import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import { selectCurrencyFormat, selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import ModalViewWorkflowByPayroll from 'features/configuration/pages/SetupWorkflowType/components/ModalViewWorkflowByPayroll';
import { notificationToast } from 'components/notificationToast';
import {
  createPayRun,
  createPayroll,
  deletePayRun,
  deletePayroll,
  getPayRuns,
  updatePayRun,
  updatePayroll,
} from 'features/payroll/payrollAction';
import { selectPayroll } from 'features/payroll/payrollSlice';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { getUser } from 'utils/auth';
import { removeAccents } from 'utils/text';
import { checkPermissionCurrentUser } from '../../../ManageRequest/functionForWorkFlow';
import { PayRunModal } from './PayRunModal';
import { PayRunDetailModal } from './PayRunDetailModal';
import MaskedValueRenderer from 'components/MaskedValueRenderer';
import { Notification } from 'constants/notification';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  VIEWWL = 'VIEWWL',
}

enum TypeEditPayroll {
  TITLE = 'title',
  USER = 'user',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;

export const PayRuns = (props: any) => {
  const { year } = props;
  const dispatch = useDispatch();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [mode, setMode] = useState<string>('');
  const [isPayroll, setIsPayroll] = useState<any>(null);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const { t } = useTranslation(['menu', 'action', 'employee', 'timesheet', 'payroll']);
  const history = useHistory();
  const payRunData = useAppSelector(selectPayroll)?.payRunData?.result;
  const loadingPayRunData = useAppSelector(selectPayroll)?.loadingPayRunData;
  const [data, setData] = useState([]); //dataMockPayRun
  const formatDate = useAppSelector(selectFormatDate);
  const currencyFormat = useAppSelector(selectCurrencyFormat);

  useEffect(() => {
    const params = {
      year: year ? moment(year).format('YYYY') : '',
    };
    dispatch(getPayRuns(params));
  }, [year, dispatch]);

  const reformatData = (data: any) => {
    return data?.map((item: any) => ({
      ...item,
      children: item?.payrollDetails?.map((i: any) => ({
        ...i,
        id: `${item?.id}_${i?.id}`,
        period: item?.period,
      })),
    }));
  };

  useEffect(() => {
    if (payRunData) setData(reformatData(payRunData));
  }, [payRunData]);
  const [formView] = Form.useForm();

  const MENU = [
    {
      name: t('payroll:addNewPayRun'),
      icon: <PlusOutlined />,
      handleClick: (value: any) => {
        setMode(MODE.ADD);
        setIsPayroll(value);
      },
    },
    {
      name: t('payroll:editPayroll'),
      width: 100,
      minWidth: 100,
      icon: <EditOutlined style={{ color: '#F1592A' }} />,
      handleClick: (data: any) => {
        setCurrentItem(data);
        setMode(MODE.UPDATE);
      },
    },
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteOutlined />,
      handleClick: (data: any) => {
        dispatch(
          deletePayRun({
            id: data?.id,
            callBack: () =>
              dispatch(
                getPayRuns({
                  year: year ? moment(year).format('YYYY') : '',
                }),
              ),
          }),
        );
      },
    },
  ];

  const cutStringCharacter = (string: string) => {
    let arr = string?.split('_');
    return arr[1];
  };

  const MENU_CHILD = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      checkDisabled: (record: any) =>
        !checkPermissionCurrentUser(record?.workflowDetails, 'view', record?.status, getUser()?.id),
      handleClick: (value: any) => {
        history.push(`/payroll/pay-runs/detail/${cutStringCharacter(value?.id)}`);
      },
    },
    {
      name: t('payroll:viewWorkflowByPayroll'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      checkDisabled: (record: any) =>
        !checkPermissionCurrentUser(record?.workflowDetails, 'view', record?.status, getUser()?.id),
      handleClick: (value: any) => {
        setCurrentItem(value);
        setMode(MODE.VIEWWL);
      },
    },
    {
      name: t('payroll:editPayRun'),
      width: 100,
      minWidth: 100,
      checkDisabled: (record: any) =>
        !checkPermissionCurrentUser(record?.workflowDetails, 'edit', record?.status, getUser()?.id),
      icon: <EditOutlined style={{ color: '#F1592A' }} />,
      handleClick: (data: any) => {
        setCurrentItem(data);
        setIsPayroll(data);
        setMode(MODE.UPDATE);
      },
    },
    {
      name: t('action:delete'),
      type: 'delete',
      checkDisabled: (record: any) =>
        !checkPermissionCurrentUser(
          record?.workflowDetails,
          'delete',
          record?.status,
          getUser()?.id,
        ),
      icon: <DeleteOutlined />,
      handleClick: (data: any) => {
        dispatch(
          deletePayroll({
            id: cutStringCharacter(data?.id),
            callBack: () => {
              dispatch(
                getPayRuns({
                  year: year ? moment(year).format('YYYY') : '',
                }),
              );
            },
          }),
        );
      },
    },
  ];

  const handleShowMenu = (record: any) => {
    return record?.status ? MENU_CHILD : MENU;
  };
  const COLUMNS: any = [
    {
      title: t('payroll:name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      minWidth: 100,
      ellipsis: true,
      align: 'left',
    },
    {
      title: t('payroll:payPeriod'),
      dataIndex: 'period',
      key: 'period',
      align: 'left',
      width: 60,
      minWidth: 60,
    },
    {
      title: t('payroll:timeRange'),
      dataIndex: 'timeRange',
      key: 'timeRange',
      align: 'left',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return record?.startDate && record?.endDate
          ? `${moment(record?.startDate).format(formatDate)} - ${moment(record?.endDate).format(
              formatDate,
            )}`
          : '';
      },
    },
    {
      title: t('payroll:payDate'),
      dataIndex: 'payDate',
      key: 'payDate',
      align: 'left',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return record?.paidDate ? `${moment(record?.paidDate).format(formatDate)}` : '';
      },
    },
    {
      title: t('payroll:description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 100,
      minWidth: 100,
      ellipsis: true,
    },
    {
      title: t('payroll:status'),
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      width: 100,
      minWidth: 100,
    },
    {
      title: t('payroll:totalCompanyCost'),
      dataIndex: 'totalCompanyCost',
      key: 'totalCompanyCost',
      align: 'right',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return <MaskedValueRenderer value={text} />;
      },
    },
    {
      title: t('payroll:totalNetPay'),
      dataIndex: 'totalReceiveSalary',
      key: 'totalReceiveSalary',
      align: 'right',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return <MaskedValueRenderer value={text} />;
      },
    },
    {
      title: t('employee:employee_information_fields.action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 60,
      minWidth: 60,
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={handleShowMenu(record)} data={record} />;
      },
    },
  ];

  const addNewPayRun = (form: FormInstance<any>, handleClose: any) => {
    form.validateFields().then((value: any) => {
      const body = {
        period: value?.payPeriod ? moment(value?.payPeriod, 'YYYY-MM').format('YYYY-MM') : '',
        name: value?.name || '',
        description: value?.description || '',
      };
      dispatch(
        createPayRun({
          body,
          callBack: () => {
            dispatch(
              getPayRuns({
                year: year ? moment(year).format('YYYY') : '',
              }),
            );
            handleClose();
          },
        }),
      );
    });
  };

  const updatePayrun = (form: FormInstance<any>, id: any, handleClose: any) => {
    form.validateFields().then((value: any) => {
      const body = {
        period: value?.payPeriod ? moment(value?.payPeriod, 'YYYY-MM').format('YYYY-MM') : '',
        name: value?.name || '',
        description: value?.description || '',
      };
      dispatch(
        updatePayRun({
          body,
          id,
          callBack: () => {
            dispatch(
              getPayRuns({
                year: year ? moment(year).format('YYYY') : '',
              }),
            );
            handleClose();
          },
        }),
      );
    });
  };

  const createPayRoll = (form: FormInstance<any>, users: any, handleClose: any) => {
    form.validateFields().then((value: any) => {
      const body = {
        name: value?.name || '',
        description: value?.description || '',
        startDate: value?.timeRange[0] ? moment(value?.timeRange[0]).format('YYYY-MM-DD') : null,
        endDate: value?.timeRange[1] ? moment(value?.timeRange[1]).format('YYYY-MM-DD') : null,
        paidDate: value?.payDate ? moment(value?.payDate).format('YYYY-MM-DD') : null,
        payrollGroupId: isPayroll?.id,
        workFlowTypeId: value?.workflow || '',
        userIds: users?.map((item: any) => item?.id) || [],
      };

      // Store initial payRunData for comparison
      const initialPayRunData = JSON.stringify(payRunData);

      dispatch(
        createPayroll({
          body,
          callBack: (res) => {
            if (res?.statusCode === 201) {
              handleClose();
              // Show processing notification
              notificationToast(
                Notification.Type.Info,
                t('payroll:processingPayroll'),
                0, // Don't auto close
              );

              const pollPayRunData = () => {
                dispatch(
                  getPayRuns({
                    year: year ? moment(year).format('YYYY') : '',
                  }),
                ).then((action) => {
                  const newPayRunData = JSON.stringify(action.payload?.data?.result);
                  if (!(newPayRunData && newPayRunData !== initialPayRunData)) {
                    setTimeout(pollPayRunData, 2000);
                  } else {
                    // Show success notification
                    notificationToast(
                      'success',
                      t('payroll:payrollCreated'),
                      3, // Close after 3 seconds
                    );
                  }
                });
              };
              pollPayRunData();
            }
          },
        }),
      );
    });
  };

  const updatePayRoll = (form: FormInstance<any>, id: any, handleClose: any) => {
    form.validateFields().then((value: any) => {
      const body = {
        name: value?.name || '',
        description: value?.description || '',
        startDate: value?.timeRange[0] ? moment(value?.timeRange[0]).format('YYYY-MM-DD') : null,
        endDate: value?.timeRange[1] ? moment(value?.timeRange[1]).format('YYYY-MM-DD') : null,
        paidDate: value?.payDate ? moment(value?.payDate).format('YYYY-MM-DD') : null,
        workFlowTypeId: value?.workflow || '',
        type: TypeEditPayroll.TITLE,
      };
      dispatch(
        updatePayroll({
          id: cutStringCharacter(id),
          body,
          callBack: () => {
            dispatch(
              getPayRuns({
                year: year ? moment(year).format('YYYY') : '',
              }),
            );
            handleClose();
          },
        }),
      );
    });
  };

  const handleSearch = (value: string) => {
    const text = value && removeAccents(value.toLowerCase());
    if (text) {
      const temp = payRunData?.filter((item: any) => {
        return item?.name && removeAccents(item?.name?.toLowerCase()).includes(text);
      });
      setData(reformatData(temp));
    } else {
      setData(reformatData(payRunData));
    }
  };

  return (
    <Container>
      <ContainerBgWhite>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <ITVSearch handleSearch={handleSearch} />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <CustomButton
              onClick={() => {
                setMode(MODE.ADD);
              }}
            >
              <PlusOutlined /> {t('payroll:addNew')}
            </CustomButton>
          </div>
        </div>
        <ITVTable
          loading={loadingPayRunData}
          isRowSelect={false}
          columns={COLUMNS}
          data={data}
          width={1080}
          height={550}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </ContainerBgWhite>
      {isPayroll ? (
        <PayRunDetailModal
          mode={mode}
          setMode={setMode}
          currentItem={currentItem}
          isPayroll={isPayroll}
          setIsPayroll={setIsPayroll}
          setCurrentItem={setCurrentItem}
          callBack={(form: any, users: any, handleClose: any, id?: any) => {
            mode === MODE.ADD
              ? createPayRoll(form, users, handleClose)
              : updatePayRoll(form, id, handleClose);
          }}
        />
      ) : (
        (mode === MODE.ADD || mode === MODE.UPDATE) && (
          <PayRunModal
            mode={mode}
            setMode={setMode}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            setIsPayroll={setIsPayroll}
            callBack={(form: any, handleClose: any, id?: any) => {
              mode === MODE.ADD
                ? addNewPayRun(form, handleClose)
                : updatePayrun(form, id, handleClose);
              /*dispatch get all pay runs*/
            }}
          />
        )
      )}
      {mode === MODE.VIEWWL && (
        <ModalViewWorkflowByPayroll
          currentItem={currentItem}
          form={formView}
          // onSave={onSave}
          mode={mode}
          setMode={setMode}
          // idRecord={idRecord}
          // setCurrentItem={setCurrentItem}
        />
      )}
    </Container>
  );
};
